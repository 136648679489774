import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './../store';
import { GridFilterModel } from '@mui/x-data-grid-pro';

export interface FilterProps {
  filterModel: GridFilterModel | undefined;
  showBvn: boolean;
  showBfs: boolean;
}

const initialState: FilterProps = {
  filterModel: undefined,
  showBvn: true,
  showBfs: true,
};

export const backlogFilterSlice = createSlice({
  name: 'backlogFilter',
  initialState,
  reducers: {
    setShowBvn: (state, action: PayloadAction<boolean>) => {
      state.showBvn = action.payload;
    },
    setShowBfs: (state, action: PayloadAction<boolean>) => {
      state.showBfs = action.payload;
    },
    setFilterModel: (state, action: PayloadAction<GridFilterModel | undefined>) => {
      state.filterModel = action.payload;
    },
  },
});

export const { setShowBfs, setShowBvn, setFilterModel } = backlogFilterSlice.actions;

export const selectBacklogFilter = (state: RootState) => state.backlogFilter;

export default backlogFilterSlice.reducer;
