import React, { FC } from 'react';
import { Add } from '@mui/icons-material';
import { Typography, Link, Portal } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { StyledLink } from '../Components';
import { addRank, getNeedForRevisionValue } from './BacklogModel';
import { Filter } from './BacklogFilter';
import { RankCell } from './RankCell';
import { FilterProps, selectBacklogFilter, setFilterModel } from './filterSlice';
import { useQuery } from '@apollo/client';
import LoadingSpinner from '../LoadingSpinner';
import { formatDateOnly } from '../Formatters';
import { GET_ALL } from './backlog.graphql';
import { ExcelExport } from './ExcelExport';
import { Criterion, selectCriterion } from './criterionSlice';
import { Box, Stack, useMediaQuery, useTheme } from '@mui/system';
import { BacklogItem, BacklogItemWithRank } from './types';
import { CategoryModel, NeedForRevision } from '../__generated__/graphql';
import { useAuth } from 'oidc-react';
import { StyledPaper } from '../theme';
import { useModal } from '../dialogs/useModal';
import { NeedForRevisionDialog, NeedForRevisionDialogInput } from './NeedForRevisionDialog';
import {
  DataGridPro,
  GridApiPro,
  GridFilterOperator,
  GridRenderCellParams,
  GridToolbarQuickFilter,
  getGridSingleSelectOperators,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { nbNO } from '@mui/x-data-grid-pro/locales';
import { GuideStatusIcon } from '../guides/GuideStatusIcon';
import { getGuideStatusDescription } from '../guides/GuideModel';

export function Overview() {
  const auth = useAuth();
  const profile = auth.userData?.profile;
  const filter: FilterProps = useSelector(selectBacklogFilter);
  const { ignoreCriteria }: Criterion = useSelector(selectCriterion);
  const apiRef = useGridApiRef();

  const { data, loading } = useQuery(GET_ALL, { fetchPolicy: 'cache-and-network' });

  const filteredItems = data?.guides && filterItems(data.guides, filter);
  const rankedItems = filteredItems && addRank(filteredItems, ignoreCriteria);

  return (
    <StyledPaper>
      <Typography variant="h5">Planlegging</Typography>
      {rankedItems && <ExcelExport apiRef={apiRef} />}
      <Filter />
      {loading && !data && <LoadingSpinner />}
      {rankedItems && <BacklogDataGrid items={rankedItems!} loading={loading} subjectId={profile?.sub} apiRef={apiRef} />}
    </StyledPaper>
  );
}

const BacklogDataGrid: FC<{
  items: BacklogItemWithRank[];
  loading: boolean;
  subjectId?: string;
  apiRef: React.MutableRefObject<GridApiPro>;
}> = ({ items, loading, subjectId, apiRef }) => {
  const theme = useTheme();
  const hasSpace = useMediaQuery(theme.breakpoints.up('lg'));
  const { filterModel } = useSelector(selectBacklogFilter);
  const dispatch = useDispatch();

  const categories = toDistictCategories(items);
  const myCategories = toDistictCategories(items.filter((x) => x!.owner?.subjectId === subjectId));

  const allGuideStatusMessages = items.map((x) => getGuideStatusDescription(x.status!, x.hasChangesSinceLastPublish, x.ongoingRevision !== null));
  const distinctGuideStatusMessages = Array.from(new Set(allGuideStatusMessages));
  distinctGuideStatusMessages.sort();

  const operator: GridFilterOperator<any, string> = {
    label: 'er mine',
    value: 'mine',
    getApplyFilterFn: (_filterItem, _column) => {
      return (value, _row, _column, _apiRef) => {
        return myCategories?.includes(value) ?? false;
      };
    },
  };

  return (
    <Stack spacing={2} marginTop={2}>
      <Box id="filter-panel" />
      <DataGridPro
        rows={items}
        loading={loading}
        apiRef={apiRef}
        disableRowSelectionOnClick
        localeText={nbNO.components.MuiDataGrid.defaultProps.localeText}
        pagination
        onFilterModelChange={(model) => dispatch(setFilterModel(model))}
        slots={{
          toolbar: CustomToolbar,
        }}
        density="compact"
        columns={[
          { field: 'rank', headerName: 'Score', type: 'number', width: 100, renderCell: (params) => <RankCell item={params.row} /> },
          {
            field: 'status',
            headerName: 'Status',
            type: 'singleSelect',
            valueOptions: distinctGuideStatusMessages.map((x) => {
              return { value: x, label: x };
            }),
            valueGetter: (_, row: BacklogItemWithRank) => {
              const message = getGuideStatusDescription(row.status!, row.hasChangesSinceLastPublish, row.ongoingRevision !== null);
              return message;
            },
            renderCell: ({ row: guide }) => {
              return (
                <GuideStatusIcon
                  status={guide.status!}
                  hasRevision={guide.ongoingRevision !== null}
                  hasChangesSinceLastPublish={guide.hasChangesSinceLastPublish}
                />
              );
            },
          },
          {
            field: 'docName',
            headerName: 'Nummer',
            renderCell: (params: GridRenderCellParams<any, string>) => (
              <StyledLink to={`/guide/${params.row.id}`}>{params.value ?? <span style={{ fontStyle: 'italic' }}>Uten navn</span>}</StyledLink>
            ),
          },
          {
            field: 'docTitle',
            headerName: 'Tittel',
            flex: 2,
          },
          {
            field: 'edition',
            headerName: 'Utgave',
            type: 'number',
            valueGetter: (_: string, row: BacklogItemWithRank) => {
              return row.planningScores?.edition?.value;
            },
            valueFormatter: (value: number) => {
              return value?.toString();
            },
          },
          {
            field: 'pageViews',
            headerName: 'Visninger',
            type: 'number',
            valueGetter: (_: string, row: BacklogItemWithRank) => {
              return row.planningScores?.pageViews?.value;
            },
            valueFormatter: (value: number) => {
              return value?.toLocaleString('no-NO');
            },
          },
          {
            field: 'notices',
            headerName: 'Merknader',
            type: 'number',
            valueGetter: (_: string, row: BacklogItemWithRank) => {
              return row.notices?.filter((x) => !x?.deletedAt).length;
            },
            renderCell: (params: GridRenderCellParams<NonNullable<BacklogItem>, undefined>) => (
              <span
                title={params.row.notices
                  ?.filter((x) => !x?.deletedAt)
                  .map((n) => `${n!.notice}\n${formatDateOnly(n!.createdAt)}`)
                  .join('\n')}>
                {params.row.notices?.filter((x) => !x?.deletedAt).length}
              </span>
            ),
          },
          {
            field: 'needForRevision',
            headerName: 'Prioritert',
            type: 'singleSelect',
            valueOptions: [
              { value: getNeedForRevisionValue(NeedForRevision.Critical), label: '1. Trenger revision' },
              { value: getNeedForRevisionValue(NeedForRevision.High), label: '2. ' },
              { value: getNeedForRevisionValue(NeedForRevision.Medium), label: '3' },
              { value: getNeedForRevisionValue(NeedForRevision.Low), label: '4' },
              { value: getNeedForRevisionValue(NeedForRevision.NextTime), label: '5' },
            ],
            valueGetter: (value: NeedForRevision, _row: BacklogItemWithRank) => {
              return getNeedForRevisionValue(value);
            },
            renderCell: (params: GridRenderCellParams<NonNullable<BacklogItem>, undefined>) => (
              <span title={params.row.needForRevisionComment ?? undefined}>
                <MarkForRevision item={params.row} />
              </span>
            ),
          },
          {
            field: 'tasksAndComments',
            headerName: 'Oppgaver/Kommentarer',
            type: 'number',
            valueGetter: (_value, row) => {
              return row.tasks?.length ?? 0 + (row.comments?.length ?? 0);
            },
            valueFormatter: (value: number, row) => {
              return value?.toLocaleString('no-NO');
            },
            renderCell: (params: GridRenderCellParams<NonNullable<BacklogItem>, undefined>) => (
              <span>
                <span
                  title={params.row.tasks
                    ?.map((t) => `${t?.sourceDescription}: ${t?.title}\n${t?.text}\n${t?.createdByName} ${formatDateOnly(t?.createdAt)}\n`)
                    .join('\n')}>
                  {params.row.tasks?.length}
                </span>
                <span title="">/</span>
                <span title={params.row.comments?.map((c) => `${c!.comment}\n${c!.createdBy?.name} ${formatDateOnly(c!.createdAt)}\n`).join('\n')}>
                  {params.row.comments?.length}
                </span>
              </span>
            ),
          },
          {
            field: 'mainCategory',
            headerName: 'Fagområde',
            type: 'singleSelect',
            valueOptions: categories.map((x) => {
              return { value: x, label: x };
            }),
            filterOperators: [...getGridSingleSelectOperators(), operator],
            flex: 1,
            valueGetter: (value: CategoryModel) => {
              if (!value) {
                return undefined;
              }
              return value.title;
            },
          },
          {
            field: 'lastPublishedAt',
            headerName: 'Sist endret',
            type: 'date',
            valueGetter: (value: string) => {
              if (!value) {
                return undefined;
              }
              return new Date(value);
            },
            valueFormatter: (value?: Date) => {
              return value ? formatDateOnly(value) : '';
            },
          },
        ]}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 50,
            },
          },
          sorting: {
            sortModel: [
              {
                field: 'rank',
                sort: 'desc',
              },
            ],
          },
          filter: {
            filterModel: filterModel,
          },
          columns: {
            columnVisibilityModel: {
              edition: hasSpace,
              pageViews: hasSpace,
              notices: hasSpace,
              needForRevision: hasSpace,
              tasksAndComments: hasSpace,
            },
          },
        }}
      />
    </Stack>
  );
};

function CustomToolbar(_props: any) {
  return (
    <React.Fragment>
      <Portal container={() => document.getElementById('filter-panel')!}>
        <GridToolbarQuickFilter sx={{ width: '100%' }} />
      </Portal>
    </React.Fragment>
  );
}

function filterItems(items: BacklogItem[], { showBvn, showBfs }: FilterProps) {
  const filteredGuides = items.filter((x) => x && (showBfs || x.docName?.startsWith('BVN')) && (showBvn || !x.docName?.startsWith('BVN')));
  return filteredGuides;
}

export function MarkForRevision({ item }: { item: NonNullable<BacklogItem> }) {
  const modal = useModal<NeedForRevisionDialogInput, unknown>({ data: item });
  const openPriorityDialog = () => {
    modal.open(item);
  };

  return (
    <>
      <Link
        onClick={openPriorityDialog}
        title={item.needForRevision ? (item.needForRevisionComment ?? undefined) : 'Angi behov for revisjon'}
        style={{ padding: 5 }}
        component="button"
        variant="button">
        {item.needForRevision && item.needForRevision !== NeedForRevision.Undefined ? (
          <Typography style={{ width: 20, fontSize: 14 }}>{getNeedForRevisionValue(item.needForRevision)}</Typography>
        ) : (
          <Add fontSize="small" />
        )}
      </Link>
      {modal.isOpen && <NeedForRevisionDialog modal={modal} />}
    </>
  );
}

function toDistictCategories(items: BacklogItem[]): string[] {
  const set = new Set<string>();
  items.forEach((x) => {
    if (x?.mainCategory?.title) {
      set.add(x.mainCategory.title);
    }
  });
  return Array.from(set).sort();
}
